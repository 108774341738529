import React, { useEffect, useState } from 'react'

export  default function Progress() {

    const [scrollPosition, setScrollPosition] = useState(0)

    function getDocHeight() {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        )
    }

    function calculateScrollDistance() {
        const scrollTop = window.pageYOffset
        const winHeight = window.innerHeight
        const docHeight = getDocHeight()
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = scrollTop / totalDocScrollLength * 100
        return scrollPostion
    }

    useEffect(() => 
        document.addEventListener("scroll", () => {
            requestAnimationFrame(() => {
                setScrollPosition(calculateScrollDistance())
            })
        })
    , [])

    return (
        <div style={{
            position:  'fixed',
            transition: 'background 0.5s ease-in',
            background:  `linear-gradient(to right, rgba(0, 0, 0, .8) ${scrollPosition}%, transparent  0)`,
            width:  '100%',
            height:  '4px',
            zIndex:  3
        }} />
    )
}