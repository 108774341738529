import React, { useState } from 'react'
import Wrap from '../components/wrap'

export default function SectionSubscribe() {
    const [selected_option, select] = useState('Both')
    return (
        <div className="tw-pb-12">
            <Wrap>
                <div className="tw-mt-12 tw-flex tw-flex-col tw-items-center">
                    <div className="sm:tw-w-3/5">
                        <div className="tw-text-center tw-text-md sm:tw-text-2xl tw-font-nobel tw-font-light tw-tracking-normal tw-px-10">
                            Subscribe to be the first to know about new product
                            releases, styling ideas and more.
                        </div>
                        <div className="tw-text-center tw-font-nobel tw-mt-2">
                            What products are you interested in?
                        </div>
                        <form
                            id="blog-drip-form"
                            action="https://www.getdrip.com/forms/853016518/submissions"
                            method="post"
                            data-drip-embedded-form="853016518"
                            data-form-type="subscribe-form"
                        >
                            <input
                                aria-hidden
                                type="hidden"
                                name="fields[preference]"
                                value={String(selected_option).toLowerCase()}
                            />
                            <div className="tw-mt-3 tw-flex tw-justify-center tw-items-center">
                                {['Prints', 'Solids', 'Both'].map((choice) => {
                                    return (
                                        <div
                                            onClick={() => select(choice)}
                                            className="tw-flex tw-justify-center tw-items-center tw-mx-1"
                                        >
                                            <div className="tw-m-1 tw-border tw-border-black tw-border-solid tw-rounded-full tw-flex tw-justify-center tw-items-center tw-p-1">
                                                <input
                                                    onChange={() => true}
                                                    type="radio"
                                                    name="radio"
                                                    className={`tw-appearance-none tw-rounded-full ${
                                                        selected_option ===
                                                        choice
                                                            ? 'tw-bg-black'
                                                            : ''
                                                    }`}
                                                    checked={
                                                        selected_option ===
                                                        choice
                                                    }
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                    }}
                                                />
                                            </div>
                                            <label className="tw-font-gotham tw-text-sm tw-mt-1">
                                                {choice}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="tw-flex tw-justify-center tw-mt-4">
                                <input
                                    required={true}
                                    name="fields[email]"
                                    placeholder="Email Address"
                                    className="tw-font-gotham tw-text-sm tw-rounded-l-md tw-border tw-border-gray-600 tw-p-2 tw-px-3"
                                ></input>
                                <input
                                    aria-label={'Subscribe'}
                                    value={'Subscribe'}
                                    type="submit"
                                    className="tw-font-gotham tw-text-xs tw-uppercase tw-tracking-widest tw-text-white tw-bg-black tw-py-3 tw-px-4 tw-rounded-r-md tw-cursor-pointer"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Wrap>
        </div>
    )
}
