import React from 'react'

export default function Wrap({ children, classes = '', ...rest }) {
    return (
        <div className="tw-flex tw-justify-center" {...rest}>
            <div className={`tw-w-full tw-max-w-screen-lg ${classes}`} >
                {children}
            </div>
        </div>
    )
}

export function SectionWrap({ children }) {
    return (
        <div style={{ width: '100%', maxWidth: '1440px' }}>
            {children}
        </div>
    )
}
