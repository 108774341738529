import Link from '../../components/link'
import sanity from '../../data/sanity'
import moment from 'moment'

interface ArticlePreview {
    main?: boolean
    center?: boolean
    horizontal?: boolean
    post?: Post | null
}

export default function ArticlePreview({ post }: ArticlePreview) {
    if (!post) return null
    const url = `/post/${post.slug.current}`
    const author_url = '/author/' + post.author.slug.current
    return (
        <div className="tw-my-4 sm:tw-my-0 tw-cursor-pointer">
            <Link href={url} as={url} prefetch={false}>
                <div className="tw-relative tw-pb-2/3">
                    <img
                        className={
                            'tw-absolute tw-object-top tw-h-full tw-w-full tw-object-cover'
                        }
                        src={sanity()
                            .builder.image(post.banner)
                            .height(250)
                            .width(375)
                            .url()}
                    />
                </div>
            </Link>
            <div
                className="tw-flex tw-flex-col tw-mt-6 tw-px-4"
                style={{ height: 140 }}
            >
                <div className="tw-mb-3 tw-font-gotham tw-tracking-widest tw-text-xs tw-uppercase tw-opacity-70 tw-leading-normal tw-text-center">
                    {post.categories && post.categories[0]}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {post.published_at &&
                        moment(post.published_at).format('MMM D, YYYY')}
                </div>
                <div className="tw-flex-grow">
                    <Link
                        href={url}
                        as={url}
                        prefetch={false}
                        inactiveClassName="tw-cursor-pointer"
                    >
                        <a>
                            <div className="tw-px-2 sm:tw-px-0 tw-mb-1 tw-font-nobel tw-font-light tw-text-lg tw-leading-normal tw-tracking-wider tw-text-xl sm:tw-text-lg tw-text-center">
                                {post.title}
                            </div>
                        </a>
                    </Link>
                </div>
                <div
                    className={`tw-font-gotham tw-text-gray-500 tw-text-xs tw-font-bold tw-tracking-widest tw-text-center tw-opacity-70`}
                >
                    <span className="tw-uppercase ">BY </span>
                    <Link
                        href={author_url}
                        as={author_url}
                        prefetch={false}
                        inactiveClassName="tw-cursor-pointer"
                    >
                        <span className="tw-uppercase">{post.author.name}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
