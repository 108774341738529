import Wrap from '../../components/wrap'
import Head from 'next/head'
import { localize, absoluteUrl } from '../../utils'
import { cache_client, cache_private } from '../../data/sanity'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../structure/footer'
import Subscribe from '../../structure/subscribe'
import Category from '../../structure/category'
import React from 'react'
import Progress from '../../structure/progress'
import { Header, SubHeader } from '../index'
import Modal from '../../components/modal'
import SearchModal from '../../structure/search'
import { AuthorProfile, ShareArticle } from '../../components/components'
import { reading_time, render_post_item } from '../../components/block'
import Commento from '../../components/commento'

// export const config = { amp: true }
export default function Post({ post, url, homepage }) {
    const state = Modal.hook(false)
    const text = post.body.map(blocksToText).join(' ')
    return (
        <div>
            <Head>
                <title>{(post.seo && post.seo.seo_title) || post.title}</title>
                <meta
                    property="og:title"
                    content={(post.seo && post.seo.seo_title) || post.title}
                />
                <meta
                    property="og:description"
                    content={
                        (post.seo && post.seo.meta_description) || post.preview
                    }
                />
                <meta
                    property="og:image"
                    content={cache_client.builder
                        .image(post.banner)
                        .width(1200)
                        .height(630)
                        .focalPoint(0.5, 0)
                        .fit('crop')
                        .url()}
                />
             
            </Head>
            <div id="fb-root"></div>
            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=184298615962647&autoLogAppEvents=1" nonce="Q4KtXkrO"></script>
            <div className="tw-fixed tw-w-full">
                <Progress />
            </div>
            <div className="tw-w-full tw-justify-center tw-flex tw-flex-col">
                <Header />
                <SubHeader />
                <Category
                    categories={homepage.categories}
                    toggle_search={() => state.show()}
                />
                <Wrap classes="tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-6 md:tw-px-0">
                    <div
                        className="tw-text-center tw-mt-3"
                        style={{ maxWidth: 650 }}
                    >
                        <div className="tw-mt-3 tw-text-3xl tw-font-nobel tw-font-light tw-leading-snug">
                            {post.title}
                        </div>
                        <div
                            className="tw-flex tw-my-6 tw-uppercase tw-font-gotham tw-opacity-75 tw-justify-center"
                            style={{ fontSize: 10 }}
                        >
                            <div
                                className="tw-px-2 tw-font-gotham tw-tracking-widest tw-uppercase tw-opacity-70 tw-leading-normal"
                                style={{ fontSize: 10 }}
                            >
                                {post.categories && post.categories[0]}
                            </div>
                            <div>|</div>
                            <div className="tw-px-2">
                                {moment(post.published_at).format(
                                    'MMM D, YYYY'
                                )}
                            </div>
                            <div>|</div>
                            <div className="tw-flex tw-px-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'clock']}
                                    size="sm"
                                    className="tw-mr-2"
                                    style={{ fontSize: 11 }}
                                />
                                <div>{reading_time(text)} MIN READ</div>
                            </div>
                        </div>
                    </div>
                    <div className="md:tw-hidden tw-flex tw-mt-3">
                        <div
                            className="tw-border-b tw-border-grey-800"
                            style={{ height: 1 }}
                        ></div>
                        <AuthorProfile author={post.author} />
                        <ShareArticle post={post} url={url} />
                    </div>
                    <div className="tw-mt-3">
                        <img
                            className=""
                            src={cache_client.builder
                                .image(post.banner)
                                .width(1100)
                                .height(500)
                                .focalPoint(0.5, 0)
                                .fit('crop')
                                .url()}
                        />
                        <div
                            className="tw-py-1 tw-tracking-widest tw-uppercase tw-opacity-75 tw-gotham"
                            style={{ fontSize: 8 }}
                        >
                            {post.caption || null}
                        </div>
                    </div>
                    <div className="tw-flex md:tw-grid tw-grid-cols-12 tw-w-full tw-py-6 tw-flex-wrap">
                        <div className="tw-hidden md:tw-flex tw-col-span-2 tw-flex-col tw-items-center">
                            <AuthorProfile author={post.author} />
                        </div>
                        <div className="tw-col-span-8 tw-flex tw-flex-col tw-space-y-20">
                            <div className="tw-mt-3">
                                {render_post_item(post.body[0])}
                            </div>
                        </div>
                        <div className="tw-hidden md:tw-block tw-col-span-2">
                            <ShareArticle post={post} url={url} />
                        </div>
                    </div>
                    <div>
                        <ShareArticle post={post} url={url} />
                    </div>
                    <Commento id="commento" />
                </Wrap>
                <Subscribe />
                <Footer />
                <SearchModal {...state.props()} onClose={() => state.hide()} />
            </div>
        </div>
    )
}

function blocksToText(blocks) {
    return blocks
        .map((item) => {
            if (item.text) return item.text
            if (!item.children || item.children.length === 0) return ''
            return blocksToText(item.children)
        })
        .join('\n\n')
}

export async function from_slug(slug, allow_draft?: boolean) {
    const results = await (allow_draft ? cache_private : cache_client).fetch(`
        {
            "homepage": *[_type == 'blog.home']{
                ...
            }[0]{
                ...,
                "categories": categories[]->.category, 
                featured[]->{
                    ...,
                    "categories": categories[]->.category, 
                    author->{name,slug}
                }
            },
            "post": *[_type == 'blog.post' && slug.current == '${slug}']{
                ...,
                author->,
                "categories": categories[]->.category
            }[0]
        }
        
    `)
    return localize(results, ['en'])
}

export async function getServerSideProps(context) {
    const localized = await from_slug(
        context.params.slug,
        Boolean(context.query.preview)
    )
    const url = absoluteUrl(context.req).full
    if (!localized)
        return {
            props: {
                post: null,
                url,
            },
        }
    return {
        props: {
            ...localized,
            url,
        },
    }
}
