import gql from 'graphql-tag'
import shopify from '../data/shopify'
import YouTube from 'react-youtube-embed'
import { cache_client, cache_private } from '../data/sanity'
import Link from './link'
import React, { useEffect, useState } from 'react'
import { FacebookProvider, Share } from 'react-facebook'
import AwesomeSlider from 'react-awesome-slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowSize } from '../utils'
import {render_post_item} from '../components/block'
const store_url = (type: string, path: string) => `https://hautehijab.com/${type}/${path}`

function shopify_variant(variant: string) {
    const encoded = Buffer.from(
        'gid://shopify/ProductVariant/' + variant
    ).toString('base64')
    return gql`
        query {
            node(id: "${encoded}") {
                ... on ProductVariant{
                    image {
                        originalSrc
                    }
                    product {
                        title,
                        handle
                    }
                }
            }
        }`
}

function share({ post, url }) {
    if (navigator.share) {
        return navigator
            .share({
                title: post.title,
                text: post.preview,
                url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error))
    }
}


export function RawHTML({ html }) {
    return <div className="rawhtml" dangerouslySetInnerHTML={{ __html: html }} />
}

export function ProductList({ products }) {
    return (
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center">
            {products.map((product) => (
                <div className="tw-mx-2">
                    <ProductPreview variant={product.variant_id} />{' '}
                </div>
            ))}
        </div>
    )
}

export function ShareArticle({ post, url }) {
    return (
        <div className="tw-px-8 tw-flex tw-flex-col tw-justify-center">
            <div
                className="tw-hidden md:tw-block tw-text-center tw-uppercase tw-opacity-75 tw-font-nobel tw-tracking-widest"
                style={{ fontSize: 8, letterSpacing: '0.1rem' }}
            >
                Share this article
            </div>
            <div className="tw-flex tw-justify-between md:tw-mt-3">
                <FacebookProvider appId="184298615962647">
                    <Share href="http://www.facebook.com">
                        {({ handleClick, loading }) => (
                            <div onClick={handleClick}>
                                <FontAwesomeIcon
                                    icon={['fab', 'facebook-square']}
                                    size="sm"
                                    className="tw-mr-2"
                                    style={{ fontSize: 20 }}
                                />
                            </div>
                        )}
                    </Share>
                </FacebookProvider>
                <a
                    href={`http://twitter.com/share?text=${post.title}&url=${url}&hashtags=`}
                >
                    <FontAwesomeIcon
                        icon={['fab', 'twitter']}
                        size="sm"
                        className="tw-mr-2"
                        style={{ fontSize: 20 }}
                    />
                </a>
                <a onClick={() => share({ post, url })}>
                    <FontAwesomeIcon
                        icon={['fas', 'share']}
                        size="sm"
                        className="tw-mr-2"
                        style={{ fontSize: 20 }}
                    />
                </a>
            </div>
        </div>
    )
}

export function AuthorProfile({ author }) {
    return (
        <Link href={`/author/${author.slug.current}`} prefetch={false}>
            <div className="tw-flex md:tw-flex-col tw-cursor-pointer">
                <div className="tw-flex tw-justify-center tw-p-2">
                    <div className="tw-rounded-full tw-w-12 tw-h-12 md:tw-w-100 md:tw-h-100">
                        <img
                            className="tw-rounded-full"
                            src={cache_client.builder
                                .image(author.image)
                                .width(150)
                                .height(150)
                                .url()}
                        />
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-justify-center md:tw-block tw-px-2">
                    <div className="md:tw-text-center tw-font-nobel">
                        {author.name}
                    </div>
                    <div
                        className="md:tw-text-center tw-font-nobel tw-uppercase tw-tracking-widest"
                        style={{ fontSize: 10, letterSpacing: '0.1rem' }}
                    >
                        {(author.role || '').replace('_', ' ')}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export function YouTubeEmbed({ id }) {
    return <YouTube id={id} />
}

export function Pullquote({ quote, author, sourceUrl }) {
    const is_long = quote.length > 200
    return (
        <div
            className={`tw-border-solid ${
                is_long ? '' : 'tw-border-l-4'
            } tw-border-gray-500 tw-pl-8`}
        >
            <div
                className={`tw-font-nobel ${
                    is_long
                        ? 'tw-text-xl tw-leading-normal tw-italic'
                        : 'tw-text-3xl'
                }`}
            >
                {quote}
            </div>

            {author && (
              <div className="tw-uppercase tw-font-gotham tw-mt-3 tw-text-sm">
                {sourceUrl ? (
                  <a target="_blank" className="tw-font-bold tw-underline" href={sourceUrl}>{author}</a>
                ) : (
                  <span className="tw-font-bold">{author}</span>
                )}
              </div>
            )}
        </div>
    )
}

export function Blockquote({ quote, source, sourceUrl }) {
    return (
        <div
            className={`tw-border-solid tw-border-l-4 tw-border-gray-500 tw-pl-8`}
        >
          <div
              className={`tw-font-nobel tw-text-xl tw-leading-normal tw-italic`}
          >
              {quote}
          </div>

          {source && (
              <div className="tw-uppercase tw-font-gotham tw-mt-3 tw-text-sm">
                  {sourceUrl ? (
                      <a target="_blank" className="tw-font-bold tw-underline" href={sourceUrl}>{source}</a>
                  ) : (
                      <span className="tw-font-bold">{source}</span>
                  )}
              </div>
          )}
        </div>
    )
}

export function ProductCallout({ title, description, preview }) {
    return (
        <div className="tw-flex tw-flex-wrap tw-justify-center md:tw-grid tw-grid-cols-2 tw-grid-rows-1">
            <div className="tw-col-span-1 tw-py-6 md:tw-pr-6">
                <div className="tw-font-nobel tw-text-2xl">{title}</div>
                <div className="tw-mt-3 tw-font-gotham tw-text-md">
                    {description}
                </div>
            </div>
            <div className="tw-col-span-1">
                <ProductPreview variant={preview.variant_id} />
            </div>
        </div>
    )
}

export function ProductPreview({ variant }) {
    let [product, set_product] = useState({ title: '', image: '', handle: '' })
    useEffect(() => {
        shopify
            .query({
                query: shopify_variant(variant),
            })
            .then((results) => {
                set_product({
                    ...results.data.node.product,
                    image: results.data.node.image.originalSrc,
                })
            })
    }, [variant])
    return (
        <div>
            <div className="tw-relative">
                <img
                    style={{ width: '100%', height: 390, minWidth: 300 }}
                    className="tw-object-cover tw-object-top"
                    src={product.image}
                />
                <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-end">
                    <a target="_blank" href={store_url('products', product.handle)}>
                        <div className="hover:tw-bg-black hover:tw-text-white tw-gotham tw-uppercase tw-px-12 tw-py-2 tw-rounded tw-cursor-pointer tw-bg-white tw-text-black tw-flex tw-mb-6">
                            Shop Now
                        </div>
                    </a>
                </div>
            </div>
            <div className="tw-py-3">
                <div className="tw-font-nobel tw-text-center">
                    {product.title}
                </div>
            </div>
        </div>
    )
}

export function Gallery(data) {
    let [index, set_index] = useState(0)
    // const wsize = useWindowSize()
    // const scale = Math.min(wsize.width / 1920, 1) * 650
    return (
        <div className="tw-flex tw-relative tw-justify-center">
            <div
                onClick={() => set_index(index - 1)}
                className="tw-hidden tw-absolute md:tw-flex tw-items-center tw-pr-8"
                style={{ height: '100%', left: -100, paddingBottom: 150 }}
            >
                <div
                    className="tw-bg-black tw-text-white tw-flex tw-justify-center tw-items-center"
                    style={{ height: 50, width: 50 }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'arrow-left']}
                        size="sm"
                        style={{ fontSize: 30 }}
                    />
                </div>
            </div>
            <AwesomeSlider
                bullets={false}
                selected={index % data.items.length}
                organicArrows={false}
                style={{ height: 800, width: '100%' }}
            >
                {data.items.map((item) => {
                    return (
                        <div
                            style={{ objectFit: 'cover', width: '100%' }}
                            data-src={cache_client.builder
                                .image(item.image)
                                .width(650)
                                .height(650)
                                .url()}
                        >
                            <div
                                className="tw-absolute tw-w-full tw-left-0 tw-bottom-0 tw-p-5"
                                style={{
                                    backgroundColor: 'white',
                                    // height: 150,
                                }}
                            >
                                <div className="tw-text-center tw-font-bold tw-text-2xl">
                                    {item.title}
                                </div>
                                <div className="tw-text-center tw-text-sm">
                                    {item.caption ? render_post_item(item.caption) : item.subtitle}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </AwesomeSlider>
            <div
                onClick={() => set_index(index + 1)}
                className="tw-hidden tw-absolute md:tw-flex tw-items-center tw-pr-8"
                style={{ height: '100%', right: -100, paddingBottom: 150 }}
            >
                <div
                    className="tw-bg-black tw-text-white tw-flex tw-justify-center tw-items-center"
                    style={{ height: 50, width: 50 }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'arrow-right']}
                        size="sm"
                        style={{ fontSize: 30 }}
                    />
                </div>
            </div>
        </div>
    )
}
