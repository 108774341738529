import Tweet from './tweet'
import { cache_client } from '../data/sanity'
import { localize } from '../utils'
import { YouTubeEmbed, Pullquote, Blockquote, ProductCallout, Gallery, ProductList, RawHTML } from './components'
import TikTok from './tiktok'

const BlockContent = require('@sanity/block-content-to-react')

export function reading_time(text: string) {
    const wordsPerMinute = 250
    let textLength = text.split(' ').length
    if (textLength > 0) {
        let value = Math.ceil(textLength / wordsPerMinute)
        return value
    }
    return 0
}

export function render_post_item(blocks) {
  return (
      <BlockContent
          blocks={blocks}
          serializers={{
              marks: {
                  link: (props) => {
                      return (
                          <a
                              href={props.mark.href}
                              target="_blank"
                              className="tw-font-bold tw-border-b hover:tw-border-b-0 hover:tw-opacity-70"
                          >
                              {props.children}
                          </a>
                      )
                  },
              },
              list: (props) => {
                if (props.type === "number") {
                  return (
                    <ul className="tw-list-decimal tw-ml-6">
                      {props.children}
                    </ul>
                  )
                } else {
                  return (
                    <ul className="tw-list-disc tw-ml-6">
                      {props.children}
                    </ul>
                  )
                }
              },
              listItem: (props) => {
                return (
                  <li className="tw-my-3 tw-font-gotham">
                    {props.children}
                  </li>
                )
              },
              types: {
                  image: (props) => {
                      if (props.node.link) {
                        return (
                          <div className="tw-flex tw-justify-center">
                            <a href={props.node.link}>
                                <img
                                  className=""
                                  src={cache_client.builder
                                      .image(props.node)
                                      .maxWidth(650)
                                      .maxHeight(650)
                                      .auto('format')
                                      .quality(100)
                                      .url()}
                                />
                                {props.node.caption && (
                                  <div className="tw-font-gotham tw-my-3 tw-leading-loose tw-italic tw-text-sm">
                                    <em>{props.node.caption}</em>
                                  </div>
                                )}
                            </a>
                          </div>
                        )
                      } else {
                        return (
                            <div className="tw-flex tw-justify-center">
                                <div>
                                    <img
                                        className=""
                                        src={cache_client.builder
                                            .image(props.node)
                                            .maxWidth(650)
                                            .maxHeight(650)
                                            .auto('format')
                                            .quality(100)
                                            .url()}
                                    />
                                    {props.node.caption && (
                                      <div className="tw-font-gotham tw-my-3 tw-leading-loose tw-italic tw-text-sm">
                                        <em>{props.node.caption}</em>
                                      </div>
                                    )}
                                </div>
                            </div>
                        )
                      }
                  },
                  span: (props) => {
                    return <span>{props.text}</span>
                  },
                  'blog.youtube': (props) => {
                      return <YouTubeEmbed id={props.node.id} />
                  },
                  'blog.tiktok': (props) => {
                      return <TikTok url={props.node.url} />
                  },
                  'blog.raw_html': (props) => (
                      <RawHTML html={props.node.html} />
                  ),
                  'blog.pullquote': (props) => (
                      <Pullquote {...localize(props.node, ['en'])} />
                  ),
                  'blog.blockquote': (props) => (
                      <Blockquote {...localize(props.node, ['en'])} />
                  ),
                  'blog.product.callout': (props) => (
                      <ProductCallout
                          {...localize(props.node, ['en'])}
                      />
                  ),
                  'blog.gallery': (props) => (
                      <Gallery {...localize(props.node, ['en'])} />
                  ),
                  'blog.product.list': (props) => (
                      <ProductList
                          {...localize(props.node, ['en'])}
                      />
                  ),
                  'blog.tweet': (props) => (
                      <div>
                          <Tweet
                              className="tw-flex tw-w-100 tw-justify-center"
                              id={props.node.tweet}
                          />
                      </div>
                  ),
                  block: (props) => {
                    if (props.node.style === "h1") {
                      return (
                        <h1 className="tw-font-gotham tw-my-3 tw-leading-loose tw-font-bold tw-text-3xl">
                          {props.children}
                        </h1>
                      )
                    } else if (props.node.style === "h2") {
                      return (
                        <h2 className="tw-font-gotham tw-my-3 tw-leading-loose tw-font-bold tw-text-2xl">
                          {props.children}
                        </h2>
                      )
                    } else {
                      return (
                        <div className="tw-font-gotham tw-my-3 tw-leading-loose">
                          {props.children}
                        </div>
                      )
                    }
                  }
              },
          }}
      />
  )
}
