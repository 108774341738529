import ApolloClient from 'apollo-boost'
import fetch from 'node-fetch'

const client = new ApolloClient({
  uri: 'https://hautehijab.myshopify.com/api/2019-10/graphql.json',
  headers: {
    'X-Shopify-Storefront-Access-Token': 'e28142ebc391d702eed2c20cfff2378e'
  },
  fetch: fetch
})

export default client