import cx from 'classnames'
import { WithRouterProps } from 'next/dist/client/with-router'
import NextLink from 'next/link'
import { withRouter } from 'next/router'
import { ReactElementLike } from 'prop-types'
import { Children, cloneElement, Component, ComponentType } from 'react'
import { NextComponentType, NextPageContext } from 'next'

interface LinkProps extends WithRouterProps {
  href: string
  children: ReactElementLike
  activeClassName: string
  inactiveClassName: string
}

class Link<P extends LinkProps> extends Component<P> {
  public render() {
    const {
      router,
      children,
      href,
      activeClassName,
      inactiveClassName,
      ...otherProps
    } = this.props

    const child = Children.only(children)
    const active = this.props.router.pathname === href && activeClassName
    const className = cx(
      (child as any).props.className,
      { [activeClassName || '']: active, [inactiveClassName || '']: !active }
    )

    return (
      <NextLink href={this.props.href} {...otherProps}>
        {cloneElement(child, { className })}
      </NextLink>
    )
  }
}

export default withRouter(Link as NextComponentType<NextPageContext, any, any>)