import imageUrlBuilder from '@sanity/image-url'
const sanityClient = require('@sanity/client')
const PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID 
const DATASET = process.env.NEXT_PUBLIC_DATASET
const IS_PROD = DATASET !== 'staging'

export function init_private_client() {
    const client = sanityClient({
        projectId: PROJECT_ID,
        dataset: DATASET,
        apiVersion: '2021-03-25',
        useCdn: false,
        token:
            'skQXw8Vd0kmWGGmZ9HLHgdAcrUINYVxtXs8rGNNn5mEEGUPEQ9lhCPK7EJoTxwLKu8zWHICwBnEU5BaT0bJ090ivIi6HzRTrQC0ysg7sU4zvrTAm2HSEdledgf3y68jKU0GuITkBZUYrh5doECdhkPRBQ8iBk87HffpDAcs9HUcl6OofZNBG',
    })
    const builder = imageUrlBuilder(client)
    client.builder = builder
    return client
}

export function init_public_client(live?: boolean, with_creds?: boolean) {
    const client = sanityClient({
        projectId: PROJECT_ID,
        dataset: DATASET,
        apiVersion: '2021-03-25',
        token: null,
        useCdn: live ? false : IS_PROD,
        withCredentials: with_creds === true,
    })
    const builder = imageUrlBuilder(client)
    client.builder = builder
    return client
}

const cache_client = init_public_client(false)
const live_client = init_public_client(true)
const cache_client_with_creds = init_public_client(true, true)
const cache_private = init_private_client()

export default function client(live?: boolean) {
    return live ? live_client : cache_client
}

export { cache_client_with_creds, cache_client, live_client, cache_private }
