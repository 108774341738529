 
import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface Props {
    active?: boolean
    selector?: string
    fill?: boolean
    onClose?: () => void
    children: any
}

export default function Modal(props: Props) {
    const ref = React.useRef() as any
    const [mounted, setMounted] = React.useState(false)
    React.useEffect(() => {
      ref.current = document.querySelector('body')
      setMounted(true)
    }, [props.selector])
    if (!mounted) return false
    return ReactDOM.createPortal(
        <div className={`tw-pa-6 tw-flex tw-justify-center tw-duration-200 tw-items-center tw-cursor-pointer tw-fixed tw-inset-0 ${props.active ? 'tw-pointer-events-auto' : 'tw-pointer-events-none'} ${props.active ? 'tw-opacity-100' : 'tw-opacity-0'}`}
            onClick={props.onClose}
            style={{
                background: 'rgba(0, 0, 0, 0.25)',
                zIndex: 1000,
            }}>
            <div
                className={`tw-bg-white tw-pa-6 ${props.fill ? 'tw-absolute tw-inset-0' : ' tw-rounded '}`}
                onClick={e => e.stopPropagation()}
                style={{
                    width: '100%',
                    // maxWidth: '55rem',
                    overflowY: 'auto',
                    cursor: 'initial'
                }}
            >
                {props.children}
            </div>
        </div>,
        ref.current
    )
}

Modal.hook = function (initial = false) {
    const [active, active_set] = React.useState(initial)
    return {
        props() {
            return {
                active
            }
        },
        show() {
            active_set(true)
        },
        hide() {
            active_set(false)
        }
    }
}