import { useEffect, useState } from 'react'

export function encode(obj) {
  let str: string[] = []
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
    }
  return str.join("&")
}

export function localize(value, languages) {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages))
  } else if (typeof value == 'object' && value !== null) {
    if (/^locale[A-Z]/.test(value._type)) {
      const language = languages.find(lang => value[lang])
      return value[language] || null
    }
    
    return Object.keys(value).reduce((result, key) => {
      result[key] = localize(value[key], languages)
      return result
    }, {})
  }
  return value || null
}

export function absoluteUrl(
    req?: any,
    localhostAddress = 'localhost:3000'
  ) {
    let host =
      (req?.headers ? req.headers.host : window.location.host) || localhostAddress
    let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'
  
    if (
      req &&
      req.headers['x-forwarded-host'] &&
      typeof req.headers['x-forwarded-host'] === 'string'
    ) {
      host = req.headers['x-forwarded-host']
    }
  
    if (
      req &&
      req.headers['x-forwarded-proto'] &&
      typeof req.headers['x-forwarded-proto'] === 'string'
    ) {
      protocol = `${req.headers['x-forwarded-proto']}:`
    }
  
    return {
      protocol,
      host,
      origin: protocol + '//' + host,
      full: protocol + '//' + host + req.url
    }
  }
  
  export function useWindowSize() {
    const isClient = typeof window === 'object';
  
    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };
    }
  
    const [windowSize, setWindowSize] = useState(getSize)
  
    useEffect(() => {
      if (!isClient) {
        return 
      }
      
      function handleResize() {
        setWindowSize(getSize())
      }
  
      window.addEventListener('resize', handleResize)
      window.addEventListener('orientationchange', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('orientationchange', handleResize)
      }
    }, []) // Empty array ensures that effect is only run on mount and unmount
  
    return windowSize
  }