import React, { useState, useEffect } from 'react'
import Wrap, { SectionWrap } from '../components/wrap'
import ArticlePreview from '../structure/article/article_preview'
import Modal from '../components/modal'
import SearchModal from '../structure/search'
import Category from '../structure/category'
import Footer from '../structure/footer'
import Subscribe from '../structure/subscribe'
import Sanity from '../data/sanity'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { localize, absoluteUrl, useWindowSize } from '../utils'
import AwesomeSlider from 'react-awesome-slider'
import Link from '../components/link'
import BrowseList, { Browser, LoadMore } from '../structure/browse'
import moment from 'moment'

//comment
function FeaturedBanner(data) {
    const featured = data.featured || []
    const [index, set_index] = useState(0)
    const wsize = useWindowSize()
    const [width, set_width] = useState(0)
    useEffect(() => {
        set_width(wsize.width || width)
    }, [wsize.width])
    const scale = Math.min(width || 1440, 1440)
    const is_small = width < 500
    return (
        <div className="tw-flex tw-relative tw-justify-center">
            <Arrow right={false} onClick={() => set_index(index - 1)} />
            <AwesomeSlider
                bullets={false}
                selected={index % featured.length}
                organicArrows={false}
                style={{ width: scale, height: is_small ? scale * 2 : 555 }}
            >
                {featured.map((post) => {
                    const url = `/post/${post.slug.current}`
                    const author_url = '/author/' + post.author.slug.current
                    return (
                        <div className="tw-flex tw-flex-col md:tw-flex-row">
                            <img
                                style={{
                                    width: is_small ? scale : 0.58 * scale,
                                    height: is_small ? scale : 555,
                                }}
                                className="tw-object-cover"
                                src={Sanity()
                                    .builder.image(post.banner)
                                    .width(830)
                                    .height(555)
                                    .url()}
                            />
                            <div
                                className="tw-bg-beige tw-flex tw-flex-col tw-p-6 md:tw-p-24 tw-items-center"
                                style={{
                                    width: is_small ? scale : 0.42 * scale,
                                    height: is_small ? scale : 555,
                                }}
                            >
                                <div className="md:tw-py-6"></div>
                                <div className="tw-font-gotham tw-tracking-widest tw-text-xs tw-uppercase tw-opacity-70 tw-leading-normal tw-text-center">
                                    {post.categories && post.categories[0]}
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    {post.published_at &&
                                        moment(post.published_at).format(
                                            'MMM DD, YYYY'
                                        )}
                                </div>
                                <div className="tw-mt-6">
                                    <Link
                                        href={url}
                                        as={url}
                                        prefetch={false}
                                        inactiveClassName="tw-cursor-pointer"
                                    >
                                        <a>
                                            <div className="tw-font-nobel tw-font-light tw-tracking-wider tw-leading-tight tw-text-3xl tw-text-center">
                                                {post.title}
                                            </div>
                                        </a>
                                    </Link>
                                </div>
                                <div className="tw-font-gotham tw-mt-6 tw-text-center tw-text-sm tw-leading-relaxed">
                                    {post.preview}
                                </div>
                                <div
                                    className={`tw-mt-6 tw-font-gotham tw-text-xs tw-font-bold tw-tracking-widest tw-text-center tw-opacity-70`}
                                >
                                    <span className="tw-uppercase">BY </span>
                                    <Link
                                        href={author_url}
                                        as={author_url}
                                        prefetch={false}
                                        inactiveClassName="tw-cursor-pointer"
                                    >
                                        <span className="tw-uppercase">
                                            {post.author.name}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </AwesomeSlider>
            <Arrow right={true} onClick={() => set_index(index + 1)} />
        </div>
    )
}

function Arrow({ right, onClick }) {
    return (
        <div
            onClick={onClick}
            className="tw-cursor-pointer tw-hidden tw-absolute md:tw-flex tw-items-center"
            style={{
                height: '100%',
                zIndex: 10,
                right: right ? 0 : undefined,
                left: right ? undefined : 0,
            }}
        >
            <div
                className="tw-bg-black tw-text-white tw-flex tw-justify-center tw-items-center"
                style={{
                    height: 50,
                    width: 50,
                }}
            >
                <FontAwesomeIcon
                    icon={['fal', right ? 'arrow-right' : 'arrow-left']}
                    size="sm"
                    style={{ fontSize: 30 }}
                />
            </div>
        </div>
    )
}

const displaySteps = 6

function BlogHome({ homepage: { categories, featured }, latest }) {
    const state = Modal.hook(false)
    const [displayed, setDisplayed] = useState(9)
    useEffect(() => {
        if (latest.length - displayed <= displaySteps) {
            // TODO -- fix paging
            console.log('load more')
        }
    }, [featured, displayed])
    return (
        <div className="tw-flex tw-flex-col tw-items-center">
            <Header />
            <SubHeader />
            <SectionWrap>
                <Category
                    categories={categories}
                    toggle_search={() => state.show()}
                />
                <FeaturedBanner
                    featured={
                        featured && featured.length > 0
                            ? featured
                            : latest.slice(0, 3)
                    }
                />
                <Wrap classes="tw-mt-12">
                    <BrowseList posts={latest.slice(0, displayed)} />
                    <LoadMore onClick={() => setDisplayed(displayed + displaySteps)} />
                </Wrap>
            </SectionWrap>
            <div className="tw-bg-beige tw-w-full">
                <Wrap classes="tw-py-8">
                    <Browser
                        categories={categories}
                        start={moment('11/2010', 'm/yyyy')}
                        end={moment()}
                    />
                </Wrap>
            </div>
            <SectionWrap>
                <Subscribe />
            </SectionWrap>
            <div className="tw-bg-gray-900 tw-w-full">
                <Footer />
            </div>
            <SearchModal {...state.props()} onClose={() => state.hide()} />
        </div>
    )
}

// migrate the preview better
// check to see if we can get shopify originals
// handle the contributors in the beginnings

export function Header() {
    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-justify-center">
                <SectionWrap>
                    <div className="tw-flex tw-justify-between tw-items-center tw-px-3 md:tw-px-12 tw-mx-4 tw-py-3">
                        <div>
                            <a
                                href="https://www.hautehijab.com"
                            >
                                <img
                                    className="tw-h-logo"
                                    src={'/img/logo-black-secondary.png'}
                                />
                            </a>
                        </div>
                        <div className="tw-flex">
                            <a
                                href="https://www.hautehijab.com"
                            >
                                <div className="tw-cursor-pointer tw-uppercase tw-font-gotham tw-font-bold tw-ml-8 tw-mr-6 tw-text-xs tw-tracking-widest-2 ">
                                    Shop
                                </div>
                            </a>
                            <a
                                href="https://www.hautehijab.com/pages/our-story"
                            >
                                <div className="tw-cursor-pointer tw-uppercase tw-font-gotham tw-font-bold tw-text-xs tw-tracking-widest-2 ">
                                    About
                                </div>
                            </a>
                        </div>
                    </div>
                </SectionWrap>
            </div>
        </div>
    )
}

function LineBreak() {
    return (
        <div
            className="tw-border-solid tw-border-b-2"
            style={{ borderBottomColor: '#F2F2F2' }}
        ></div>
    )
}

export function SubHeader() {
    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-justify-center">
                <SectionWrap>
                    <a href="/">
                        <div className="tw-px-12 tw-pb-6 tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <div
                                style={{ letterSpacing: '0.4em' }}
                                className="tw-py-3 tw-whitespace-no-wrap tw-uppercase tw-text-center tw-text-2xl md:tw-text-4xl tw-font-kum tw-font-bold tw-leading-10"
                            >
                                The Haute Take
                            </div>
                            <div
                                className="tw-font-gotham tw-text-xs tw-text-center"
                                style={{ letterSpacing: '4px' }}
                            >
                                FOR THE WORLD'S MOST POWERFUL WOMEN
                            </div>
                        </div>
                    </a>
                </SectionWrap>
            </div>
            <LineBreak />
        </div>
    )
}

export default BlogHome

export async function getStaticProps(context) {
    const results = await Sanity().fetch(`
        {
            "homepage": *[_type == 'blog.home']{
                ...
            }[0]{
                ...,
                "categories": categories[]->.category, 
                featured[]->{
                    ...,
                    "categories": categories[]->.category, 
                    author->{name,slug}
                }
            },
            "latest": *[_type == 'blog.post' && published_at <= now()]{
                ...,
                "categories": categories[]->.category, 
                author->{name,slug}
            } | order(published_at desc)[0..100]
        }
    `)
    const localized = localize(results, ['en'])
    return {
        props: localized,
        revalidate: 3600
    }
}
