import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Wrap from '../components/wrap'

export default function Footer() {
    return (
        <div className="tw-pt-12 tw-px-6 md:tw-px-0 tw-bg-beige">
            <Wrap>
                <div className="tw-w-full tw-flex tw-flex-wrap-reverse md:tw-flex-no-wrap tw-mb-20">
                    <div className="md:tw-w-1/3 tw-my-4">
                        <div className="tw-font-nobel tw-text-xl tw-mb-2">
                            What we stand for
                        </div>
                        <div className="tw-text-sm tw-my-6 tw-font-gotham">
                            To create a world where every woman feels
                            comfortable and confident.
                        </div>
                        <div>
                            <a
                                href="https://www.facebook.com/hautehijab"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={['fab', 'facebook-square']}
                                    size="lg"
                                    className="tw-mr-6"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/hautehijab/"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={['fab', 'instagram']}
                                    size="lg"
                                    className="tw-mr-6"
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/user/hautehijab"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={['fab', 'youtube']}
                                    size="lg"
                                    className="tw-mr-6"
                                />
                            </a>
                            <a
                                href="https://twitter.com/hautehijab"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={['fab', 'twitter']}
                                    size="lg"
                                    className="tw-mr-6"
                                />
                            </a>
                            <a
                                href="https://www.pinterest.com/hautehijab/"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={['fab', 'pinterest']}
                                    size="lg"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="md:tw-w-2/3 tw-flex tw-flex-wrap md:tw-flex-no-wrap tw-justify-between md:tw-justify-around">
                        <div className="tw-flex tw-flex-col tw-my-4">
                            <div className="tw-font-nobel tw-text-xl tw-mb-2">
                                Community
                            </div>
                            <Link
                                href="https://www.hautehijab.com/pages/community-rewards"
                                label="Community Rewards"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/reviews"
                                label="Customer Reviews"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/hijab-style-guide"
                                label="Style Guide"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/careers"
                                label="Careers"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/press"
                                label="Press"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/affiliates"
                                label="Affiliates"
                            />
                        </div>
                        <div className="tw-flex tw-flex-col tw-my-4">
                            <div className="tw-font-nobel tw-text-xl tw-mb-2">
                                Company
                            </div>
                            <Link
                                href="https://www.hautehijab.com/pages/our-story"
                                label="Our Story"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/our-story#our-hijabs"
                                label="Our Hijabs"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/our-story#our-team"
                                label="Our Team"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/10-years"
                                label="10 Years of HH"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/shipping-and-returns-policy"
                                label="Shipping & Returns"
                            />
                            <Link
                                href="https://www.hautehijab.com/pages/frequently-asked-questions"
                                label="FAQ"
                            />
                        </div>
                        <div className="tw-my-4">
                            <div className="tw-font-nobel tw-text-xl tw-mb-2">
                                Contact
                            </div>
                            <div className="tw-text-sm tw-mt-4 tw-font-gotham">
                                Call us Monday-Friday,
                            </div>
                            <div className="tw-text-sm tw-font-gotham">
                                9am-5pm EST or email anytime!
                            </div>
                            <a href="callto:8774126591">
                                <div className="tw-text-sm tw-mt-4 tw-font-gotham">
                                    877-412-6591
                                </div>
                            </a>
                            <a href="mailto:hello@hautehijab.com">
                                <div className="tw-text-sm tw-mt-4 tw-font-gotham">
                                    hello@hautehijab.com
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Wrap>
            <Wrap>
                <div className="tw-flex tw-flex-wrap tw-flex-col-reverse md:tw-flex-row tw-items-center md:tw-items-start md:tw-justify-between tw-mt-2 tw-mb-6">
                    <div className="tw-mt-4 tw-text-xs">
                        © 2020 Haute Hijab. All rights reserved.
                    </div>
                    <div className="tw-flex tw-flex-col md:tw-flex-row">
                        <FooterLink
                            href="https://www.hautehijab.com/pages/terms"
                            label="Terms of Service"
                        />
                        <FooterLink
                            href="https://www.hautehijab.com/pages/privacy-policy"
                            label="Privacy Policy"
                        />
                        <FooterLink
                            href="https://www.hautehijab.com/pages/press"
                            label="Press"
                        />
                        <FooterLink
                            href="https://www.hautehijab.com/pages/affiliates"
                            label="Affiliates"
                        />
                        <FooterLink
                            href="https://www.hautehijab.com/account/login"
                            label="Login"
                        />
                    </div>
                </div>
            </Wrap>
        </div>
    )
}

function FooterLink({ href, label }) {
    return (
        <a
            href={href}
            className="hover:tw-text-gray-300 tw-text-xs tw-text-center md:tw-text-left tw-mt-4 tw-ml-4"
        >
            {label}
        </a>
    )
}

function Link({ href, label }) {
    return (
        <a
            href={href}
            className="hover:tw-text-gray-300 tw-text-sm tw-mt-4 tw-font-gotham"
        >
            {label}
        </a>
    )
}
