import React, { useEffect, useState } from 'react'
import ArticlePreview from './article/article_preview'
import Link from '../components/link'

export default function BrowseList({ posts }) {
    return (
        <div className="tw-grid tw-grid-flow-rows tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
            {posts.map((post) => (
                <ArticlePreview center={true} post={post} />
            ))}
        </div>
    )
}

const encodeGetParams = (p) =>
    Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join('='))
        .join('&')

export function Browser({ categories, start, end }) {
    const [cat_select, set_cat] = useState('')
    const [m_select, set_month] = useState('')
    const [y_select, set_year] = useState('')
    const url = `/category/${encodeURIComponent(cat_select)}?${encodeGetParams({
        month: m_select,
        year: y_select,
    })}`
    const cats = categories.map((cat) => (
        <option selected={cat_select == cat}>{cat}</option>
    ))
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ].map((m) => (
        <option className="tw-text-xs tw-font-gotham" selected={m_select == m}>
            {m}
        </option>
    ))
    const years = new Array(end.diff(start, 'years')).fill(0).map((_, idx) => {
        const yr = end
            .clone()
            .add(-1 * idx, 'years')
            .year()
        return <option selected={y_select == yr}>{yr}</option>
    })
    return (
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div className="tw-font-nobel tw-text-md">
                Browse The Archives By
            </div>
            {['Category', 'Month', 'Year'].map((option, idx) => {
                return (
                    <div
                        key={idx}
                        className="tw-inline-block tw-relative tw-my-3 md:tw-my-0"
                        style={{ width: 200 }}
                    >
                        <select
                            onChange={(ev) => {
                                if (option === 'Category')
                                    return set_cat(ev.target.value)
                                if (option === 'Month')
                                    return set_month(ev.target.value)
                                if (option === 'Year')
                                    return set_year(ev.target.value)
                            }}
                            className="tw-cursor-pointer tw-text-xs tw-font-gotham tw-block tw-appearance-none tw-w-full tw-h-full tw-bg-white tw-border tw-border-gray-400 tw-hover:border-gray-500 tw-px-4 tw-py-2 tw-pr-8 tw-rounded tw-shadow tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                        >
                            <option>{option}</option>
                            {option === 'Month' && months}
                            {option === 'Year' && years}
                            {option === 'Category' && cats}
                        </select>
                        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2">
                            <svg
                                className="tw-fill-current tw-h-4 tw-w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                )
            })}
            <Link href={url} as={url} prefetch={false}>
                <div className="tw-font-gotham tw-text-xs tw-tracking-widest tw-uppercase tw-text-white tw-bg-black tw-py-3 tw-px-16 tw-rounded-sm tw-cursor-pointer">
                    Browse
                </div>
            </Link>
        </div>
    )
}

export function LoadMore({ onClick }) {
    return (
        <div
            className="tw-flex tw-justify-center tw-my-12"
            onClick={() => onClick()}
        >
            <div className="tw-mt-4 tw-w-9/12 tw-rounded-sm tw-border tw-text-xs tw-cursor-pointer tw-border-black tw-border-solid tw-text-center tw-py-2 tw-font-gotham hover:tw-bg-black hover:tw-text-white">
                LOAD MORE POSTS
            </div>
        </div>
    )
}
