import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Wrap from '../components/wrap'
import Link from '../components/link'

type CategoryProps = {
    categories?: any[],
    toggle_search?: (value: boolean) => void
}

export default function Category({ categories = [], toggle_search }: CategoryProps) {
    return (
        <div>
            <Wrap classes="tw-p-0 tw-px-4">
                <div className="tw-flex tw-flex-row tw-w-full">
                    <div className="tw-flex-grow tw-flex tw-flex-row tw-justify-between tw-py-4 tw-overflow-auto">
                        {categories.map((label) => {
                            const cat_url = `/category/${String(
                                label
                            ).toLowerCase()}`
                            return (
                                <Link
                                    href={cat_url}
                                    as={cat_url}
                                    prefetch={false}
                                    inactiveClassName="tw-opacity-50 hover:tw-opacity-100"
                                >
                                    <div className="tw-px-4 tw-whitespace-no-wrap tw-tracking-widest tw-text-xs tw-cursor-pointer tw-font-gotham tw-uppercase tw-font-bold tw-leading-normal">
                                        {label}
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                    {toggle_search && (
                        <div
                            className="tw-py-3"
                            onClick={() => toggle_search(true)}
                        >
                            <FontAwesomeIcon
                                onClick={() => focus()}
                                icon={['fal', 'search']}
                                size="lg"
                                style={{ fontSize: 20 }}
                                className="tw-cursor-pointer tw-ml-3 lg:tw-ml-20"
                            />
                        </div>
                    )}
                </div>
            </Wrap>
        </div>
    )
}
