import Search from "../data/search";
import {
  Configure,
  SearchBox,
  InstantSearch,
  connectHits,
  connectAutoComplete,
  connectInfiniteHits,
  connectSearchBox,
} from "react-instantsearch-dom";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../components/modal";
import Wrap from "../components/wrap";
import Category from "./category";
import ArticlePreview from "../structure/article/article_preview";
import { from_slug } from "../pages/post/[slug]";

export default function SearchModal({ active, onClose }) {
  let input_ref = React.createRef() as any;
  let [query, set_query] = useState("");
  React.useEffect(() => {
    if (input_ref.current) {
      input_ref.current.focus();
    }
  }, [active]);
  const VirtualSearchBox = connectSearchBox(() => null);
  const InfiniteHits = ({ hits, hasMore, refineNext }) => {
    return (
      <>
        <div className="tw-grid sm:tw-grid-cols-3 tw-rows-3 tw-gap-4 tw-mb-8">
          {hits.map((hit) => (
            <Hit hit={hit} />
          ))}
        </div>
        {hasMore && (
          <button
            className="tw-font-gotham tw-text-xs tw-tracking-widest tw-uppercase tw-text-white tw-bg-black tw-py-3 tw-px-16 tw-rounded-sm tw-cursor-pointer tw-block tw-mx-auto tw-my-16"
            onClick={refineNext}
            title="Load More"
          >Load More</button>
          )}
      </>
    );
  };
  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);
  return (
    <Modal
      active={active}
      fill={true}
      selector="body"
      onClose={() => onClose()}
    >
      <Wrap classes="tw-relative tw-p-6" style={{ height: "50rem" }}>
        <div className="tw-flex tw-justify-end">
          <div className="tw-flex tw-align-center" onClick={onClose}>
            <FontAwesomeIcon icon={["fal", "times"]} size="2x" />
          </div>
        </div>
        <div
          className="tw-mt-12 tw-flex tw-justify-between tw-items-center tw-border-solid tw-border-b-2 tw-opacity-50 tw-text-black"
          style={{ borderBottomColor: "#F2F2F2" }}
        >
          <InstantSearch searchClient={Search} indexName="blog_articles">
            <input
              ref={input_ref}
              className="tw-flex tw-w-full tw-py-2 tw-text-4xl tw-font-nobel tw-text-black"
              placeholder="What are you looking for?"
              onReset={() => set_query("")}
              onChange={(event) => {
                event.preventDefault();
                set_query(event.target.value);
              }}
            />
          </InstantSearch>
          <FontAwesomeIcon
            icon={["fal", "search"]}
            size="lg"
            className="tw-cursor-pointer tw-ml-3 lg:tw-ml-20"
          />
        </div>
        <Category />
        <div className="tw-overflow-scroll">
          <InstantSearch searchClient={Search} indexName="blog_articles">
            <Configure hitsPerPage={9} distinct />
            <VirtualSearchBox defaultRefinement={query} />
            {query !== "" && <CustomInfiniteHits />}
          </InstantSearch>
        </div>
      </Wrap>
    </Modal>
  );
}

function Hit({ hit }) {
  const [post, set_post] = useState(null);
  useEffect(() => {
    from_slug(hit.slug.current).then(({ post }) => set_post(post));
  }, [hit.slug]);
  return (
    <div key={hit.objectID} style={{ maxWidth: 316 }}>
      <ArticlePreview post={post} center={true} />
    </div>
  );
}
